<template>
  <div class="admin_board_page">
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <h4>Data 관리</h4>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Data',
  components: {},
  data() {
    return {}
  },

  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<style scoped>
/* table > tbody > tr > td { text-align: left; width: 300px;}
  table > tbody > tr > td > input { width: 100%; } */
</style>
